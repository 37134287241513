import React, { FC } from 'react';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ContactProps } from './models';

import './contactComponent.scss';

const ContactComponent: FC<ContactProps> = ({
  node: { title, body, seoMetaTitle, seoMetaDescription, seoMetaKeywords },
}) => (
  <Layout>
    <Seo {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }} />
    <PageSchema
      type="WebPage"
      name={seoMetaTitle}
      data={{
        metaTitle: seoMetaTitle,
        metaDescription: seoMetaDescription,
        metaKeywords: seoMetaKeywords,
      }}
    />
    <div className="content container contact">
      <div className="content__title">
        <h1 className="title">{title}</h1>
      </div>
      <div className="content__description">
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  </Layout>
);
export default ContactComponent;
